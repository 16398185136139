import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  TextField,
  Button,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DateTimePicker } from '@material-ui/pickers';
import firebase from '../modules/firebase';

const Event = props => {
  const { teamId, eventId } = props.match.params;
  let history = useHistory();
  const [user, setUser] = useState();
  const [creatingEvent, setCreatingEvent] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [title, setTitle] = useState('');
  const [videoChatUrl, setVideoChatUrl] = useState('');
  const [dateTime, handleDateChange] = useState(new Date());
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState({ title, videoChatUrl, notes });

  useEffect(() => {
    const getUser = async () => {
      const user = (
        await firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .get()
      ).data();
      setUser({ ...user, id: firebase.auth().currentUser.uid });
    };

    getUser();
  }, []);

  useEffect(() => {
    const getEvent = async () => {
      const eventData = (
        await firebase
          .firestore()
          .collection('teams')
          .doc(teamId)
          .collection('events')
          .doc(eventId)
          .get()
      ).data();
      setTitle(eventData.title);
      setNotes(eventData.notes);
      handleDateChange(new Date(eventData.timeOfEvent.toMillis()));
      setVideoChatUrl(eventData.videoChatUrl);
      setSelectedTeam(teamId);
    };
    if (teamId && eventId) {
      getEvent();
    }
  }, [teamId, eventId]);

  useEffect(() => {
    if (!teamId && user && user.teams) {
      setSelectedTeam(Object.keys(user.teams)[0]);
    }
  }, [user, teamId]);

  if (!user || (eventId && !title && !videoChatUrl && !notes && !dateTime)) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container justifyContent="center" style={{ marginTop: 11 }}>
        <Grid item xs={12} md={6} style={{ padding: 11 }}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 33 }}>
            Let's get some details for your event.
          </Typography>
          <FormControl>
            <Select
              value={selectedTeam}
              onChange={event => setSelectedTeam(event.target.value)}
            >
              {Object.keys(user.teams)
                .sort(
                  (teamId1, teamId2) =>
                    user.teams[teamId1].name > user.teams[teamId2].name
                )
                .map(teamId => (
                  <MenuItem key={teamId} value={teamId}>
                    {user.teams[teamId].name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              Select the team you would like to create an event for
            </FormHelperText>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label="Event Title"
            fullWidth
            value={title}
            onChange={evt => {
              if (evt.target.value.trim() === '') {
                setErrors({ ...errors, title: 'An event title is required' });
              } else {
                let newState = { ...errors };
                delete newState.title;
                setErrors(newState);
              }
              setTitle(evt.target.value);
            }}
            helperText={errors.title ? errors.title : ''}
            error={!!errors.title}
          />
          <DateTimePicker
            value={dateTime}
            onChange={handleDateChange}
            fullWidth
            style={{ marginTop: 11 }}
          />
          <TextField
            margin="dense"
            label="Video Chat URL"
            type="url"
            fullWidth
            value={videoChatUrl}
            onChange={evt => {
              if (evt.target.value.trim() === '') {
                setErrors({
                  ...errors,
                  videoChatUrl: 'A video chat URL is required'
                });
              } else if (!evt.target.value.trim().startsWith('https://')) {
                setErrors({
                  ...errors,
                  videoChatUrl: 'Your chat URL needs to start with https://'
                });
              } else {
                let newState = { ...errors };
                delete newState.videoChatUrl;
                setErrors(newState);
              }
              setVideoChatUrl(evt.target.value);
            }}
            helperText={errors.videoChatUrl ? errors.videoChatUrl : ''}
            error={!!errors.videoChatUrl}
          />
          <TextField
            label="Notes"
            fullWidth
            multiline
            rows={11}
            value={notes}
            onChange={evt => {
              if (evt.target.value.trim() === '') {
                setErrors({
                  ...errors,
                  notes: 'Some notes are required'
                });
              } else {
                let newState = { ...errors };
                delete newState.notes;
                setErrors(newState);
              }
              setNotes(evt.target.value);
            }}
            helperText={errors.notes ? errors.notes : ''}
            error={!!errors.notes}
          />
          <Box textAlign="center" marginTop={3}>
            <Button
              onClick={async () => {
                setCreatingEvent(true);
                const validation = {};
                if (!title) {
                  validation.title = 'An event title is required';
                }
                if (!videoChatUrl) {
                  validation.videoChatUrl = 'A video chat URL is required';
                }
                if (!notes) {
                  validation.notes = 'Please enter some notes on this event';
                }

                setErrors(validation);

                if (title && videoChatUrl && notes) {
                  if (!eventId) {
                    // create new event for the team
                    const newEventData = {
                      host: {
                        name: user.name,
                        id: user.id
                      },
                      timestamps: {
                        created: firebase.firestore.Timestamp.now()
                      },
                      title,
                      timeOfEvent: firebase.firestore.Timestamp.fromMillis(
                        dateTime.valueOf()
                      ),
                      notes,
                      videoChatUrl,
                      attending: {
                        [user.id]: { name: user.name }
                      },
                      teamId: selectedTeam
                    };
                    const res = await firebase
                      .firestore()
                      .collection('teams')
                      .doc(selectedTeam)
                      .collection('events')
                      .add(newEventData);
                    firebase
                      .firestore()
                      .collection('users')
                      .doc(user.id)
                      .update({
                        [`attending.${res.id}`]: newEventData
                      });
                  } else {
                    firebase
                      .firestore()
                      .collection('teams')
                      .doc(selectedTeam)
                      .collection('events')
                      .doc(eventId)
                      .update({
                        'timestamps.updated':
                          firebase.firestore.Timestamp.now(),
                        title,
                        timeOfEvent: firebase.firestore.Timestamp.fromMillis(
                          dateTime.valueOf()
                        ),
                        notes,
                        videoChatUrl
                      });
                  }

                  history.goBack();
                }
                setCreatingEvent(false);
              }}
              color="primary"
              variant="contained"
              disabled={!eventId && Object.keys(errors).length > 0}
            >
              {eventId ? 'Update event' : 'Create New Event'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={creatingEvent}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Event;
