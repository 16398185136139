import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import loginImage from '../images/space.jpg';
import Copyright from './Copyright';
import firebase from '../modules/firebase';
import loginError from '../audio/minion-fail.mp3';

const Login = () => {
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [createAccount, setCreateAccount] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const theme = useTheme();
  const history = useHistory();

  const onLogin = async evt => {
    evt.preventDefault();
    try {
      if (!email.match(/^\S+@\S+$/i)) {
        setError("Your email address doesn't look right.");
        return;
      }
      if (!password) {
        setError('Your password is required.');
        return;
      }

      setShowBackdrop(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);

      firebase.analytics().logEvent('login', { email: email });
    } catch (error) {
      new Audio(loginError).play();
      setShowBackdrop(false);
      switch (error.code) {
        case 'auth/user-not-found':
          setError(
            "I can't find your email address in our system. Typo? Are you sure you've registered with us?"
          );
          break;
        case 'auth/wrong-password':
          setError("Typing too quickly? You've entered an incorrect password.");
          break;
        default:
          setError(error.message);
      }
    }
  };

  useEffect(() => {
    // if the user is already authenticated, take them to the profile page
    const unsubscribeAuthChange = firebase
      .auth()
      .onAuthStateChanged(async user => {
        try {
          if (user) {
            history.push('/');
          }
        } catch (error) {
          console.error(`Issue redirecting to profile page: ${error}`);
        }
      });

    return () => unsubscribeAuthChange();
  }, [history]);

  return (
    <Grid
      container
      component="main"
      style={{
        height: '100vh'
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundSize: 'cover'
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div
          style={{
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h4" gutterBottom>
            Multiverse
          </Typography>
          <Typography component="h2" variant="h6" align="center">
            event management across multiple timezones
          </Typography>
          <form
            style={{
              width: '100%',
              marginTop: theme.spacing(3)
            }}
            onSubmit={onLogin}
            noValidate
          >
            {error && (
              <Paper
                style={{
                  padding: theme.spacing(2, 2),
                  color: theme.palette.error.light,
                  marginBottom: theme.spacing(3)
                }}
              >
                <Typography variant="body1">{error}</Typography>
              </Paper>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              label="Email Address"
              autoComplete="email"
              onChange={evt => setEmail(evt.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={evt => setPassword(evt.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{
                margin: theme.spacing(3, 0, 2)
              }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={() => setResetPasswordDialog(true)}
                  href="#"
                  variant="body2"
                >
                  Need to reset your password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  onClick={() => setCreateAccount(true)}
                  variant="body2"
                  href="#"
                >
                  Don't have an account?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          <Dialog
            open={createAccount}
            onClose={() => setCreateAccount(false)}
            fullScreen
          >
            <DialogTitle>Create an account</DialogTitle>
            <DialogContent>
              <DialogContentText gutterBottom>
                Welcome to Multiverse 😊
              </DialogContentText>
              <DialogContentText>
                We just need a couple of details to create your account.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                onChange={evt => setName(evt.target.value)}
              />
              <TextField
                margin="dense"
                label="Email Address"
                type="email"
                onChange={evt => setEmail(evt.target.value)}
                fullWidth
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                onChange={evt => setPassword(evt.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCreateAccount(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  try {
                    setShowBackdrop(true);
                    setCreateAccount(false);
                    const userCredential = await firebase
                      .auth()
                      .createUserWithEmailAndPassword(email, password);

                    await firebase
                      .firestore()
                      .collection('users')
                      .doc(userCredential.user.uid)
                      .set({ name: name.trim(), bio: '' });

                    firebase.analytics().logEvent('sign_up', {
                      email
                    });
                    setShowBackdrop(false);
                  } catch (error) {
                    setShowBackdrop(false);
                    setCreateAccount(false);
                    setError(
                      `${error.message} No account was created. Please try again.`
                    );
                  }
                }}
                color="primary"
              >
                Register
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={resetPasswordDialog}
            onClose={() => setResetPasswordDialog(false)}
          >
            <DialogTitle>Password Reset</DialogTitle>
            <DialogContent>
              <DialogContentText>
                If you've forgotten your password, or simply want to change it,
                just type in your email address below. If we have your email
                address in our system, we'll send you an email now.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                onChange={evt => setResetEmail(evt.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setResetPasswordDialog(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  try {
                    setResetPasswordDialog(false);
                    firebase.auth().sendPasswordResetEmail(resetEmail);
                  } catch (error) {
                    setError(error.message);
                  }
                }}
                color="primary"
              >
                Send reset email
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop
            style={{
              zIndex: theme.zIndex.drawer + 1,
              color: '#fff'
            }}
            open={showBackdrop}
            onClick={() => {
              setShowBackdrop(false);
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
