import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Backdrop,
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress,
  LinearProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowBack, CloudUpload } from '@material-ui/icons';
import firebase from '../modules/firebase';
import { getImageDimensions } from '../modules/utils';

const EditProfile = props => {
  const { userId } = props.match.params;
  const history = useHistory();
  const [user, setUser] = useState();
  const [updatedName, setUpdatedName] = useState();
  const [updatedBio, setUpdatedBio] = useState();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const unsubscribeUser = firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .onSnapshot(async userSnapshot => {
        setUser(userSnapshot.data());
        setUpdatedName(userSnapshot.data().name);
        setUpdatedBio(userSnapshot.data().bio);

        const { max } = await getImageDimensions();

        try {
          const imageUrl = await firebase
            .storage()
            .ref()
            .child(`users/${userId}/profile_${max}x${max}.jpeg`)
            .getDownloadURL();

          setProfileImageUrl(imageUrl);
        } catch (error) {
          console.error(error);
          setProfileImageUrl(`https://picsum.photos/${max}`);
        }
        setShowBackdrop(false);
      });

    return () => unsubscribeUser();
  }, [userId]);

  if (!user || !profileImageUrl) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ marginLeft: 11, marginBottom: 8 }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ArrowBack />}
            onClick={() => history.replace('/')}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 11 }}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 33 }}>
            Update your profile
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Box
                marginBottom={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Avatar
                  alt={user.name}
                  style={{
                    width: 290,
                    height: 290,
                    marginBottom: 11
                  }}
                  src={profileImageUrl}
                />
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  onChange={event => {
                    setShowBackdrop(true);
                    firebase
                      .storage()
                      .ref()
                      .child(`users/${userId}/profile.jpeg`)
                      .put(event.target.files[0]);
                  }}
                  id="icon-button-file"
                  style={{ display: 'none' }}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    size="small"
                    startIcon={<CloudUpload />}
                  >
                    Update photo
                  </Button>
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} style={{ padding: 33 }}>
              <TextField
                margin="dense"
                label="Your name"
                fullWidth
                value={updatedName}
                onChange={evt => {
                  if (evt.target.value.trim() === '') {
                    setErrors({
                      ...errors,
                      name: 'We need to know what to call you :)'
                    });
                  } else {
                    setErrors({ ...errors, name: null });
                  }
                  setUpdatedName(evt.target.value);
                }}
                helperText={errors.name ? errors.name : ''}
                error={!!errors.name}
              />
              <TextField
                margin="dense"
                label="Bio"
                multiline
                minRows={8}
                maxRows={11}
                fullWidth
                value={updatedBio}
                onChange={evt => {
                  if (evt.target.value.trim() === '') {
                    setErrors({
                      ...errors,
                      bio: 'Tell us a little about yourself :)'
                    });
                  } else {
                    setErrors({ ...errors, bio: null });
                  }
                  setUpdatedBio(evt.target.value);
                }}
                helperText={errors.bio ? errors.bio : ''}
                error={!!errors.bio}
              />
              <Box textAlign="center" marginTop={3}>
                <Button
                  onClick={async () => {
                    const validation = {};
                    if (!updatedName) {
                      validation.name = 'What would you like to be called?';
                    }
                    if (!updatedBio) {
                      validation.bio = 'But who are you? :)';
                    }

                    setErrors(validation);
                    if (!Object.keys(validation).length > 0) {
                      firebase
                        .firestore()
                        .collection('users')
                        .doc(userId)
                        .update({ name: updatedName.trim(), bio: updatedBio });
                      setUser({
                        ...user,
                        name: updatedName.trim(),
                        bio: updatedBio
                      });
                      history.goBack();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    user.name === updatedName && user.bio === updatedBio
                  }
                >
                  Update profile
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={showBackdrop} style={{ zIndex: 1 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default EditProfile;
