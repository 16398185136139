import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBLJML76VAlfEGN_rH_8CtgwQ-djcZzvGk',
  authDomain: 'multiverse-1111.firebaseapp.com',
  databaseURL: 'https://multiverse-1111.firebaseio.com',
  projectId: 'multiverse-1111',
  storageBucket: 'multiverse-1111.appspot.com',
  messagingSenderId: '181598528191',
  appId: '1:181598528191:web:e4aa3364f20d02c5921c0a',
  measurementId: 'G-NZ3DJMMWL4'
};

firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === 'localhost') {
//   firebase.firestore().useEmulator('localhost', 8080);
//   firebase.storage().useEmulator('localhost', 9199);
//   console.log('using emulator');
// }

export default firebase;
