import React, { useState, Fragment, useEffect } from 'react';
import {
  Card,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  useTheme,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { ColorBox } from 'material-ui-color';
import { MoreVert, Info, Delete } from '@material-ui/icons';
import firebase from '../modules/firebase';
import { getAcronym, getImageDimensions } from '../modules/utils';
import MemberLocalTimeInfo from './MemberLocalTimeInfo';

const Team = ({ user, team }) => {
  const theme = useTheme();
  const [updateTeamColour, setUpdateTeamColour] = useState(false);
  const [colour, setColour] = useState();
  const [inviteMember, setInviteMember] = useState({ show: false });
  const [memberDetails, setMemberDetails] = useState({ show: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMembers, setShowMembers] = useState(false);
  const [memberImageUrls, setMemberImageUrls] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  useEffect(() => {
    const getMemberImages = async () => {
      const { max } = await getImageDimensions();
      const memberImgUrls = {};
      for (const memberId of Object.keys(team.members)) {
        try {
          const imageUrl = await firebase
            .storage()
            .ref()
            .child(`users/${memberId}/profile_${max}x${max}.jpeg`)
            .getDownloadURL();
          memberImgUrls[memberId] = imageUrl;
        } catch (error) {
          memberImgUrls[memberId] = `https://picsum.photos/${max}`;
        }
      }
      setMemberImageUrls(memberImgUrls);
    };
    getMemberImages();
  }, [team.members]);

  return (
    <>
      <Card variant="outlined" style={{ marginBottom: 11 }} key={team.id}>
        <CardHeader
          avatar={
            <Avatar
              style={{
                color: theme.palette.getContrastText(team.colour),
                backgroundColor: team.colour
              }}
            >
              {getAcronym(team.name)}
            </Avatar>
          }
          action={
            <>
              <IconButton
                aria-label="actions"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setColour(team.colour);
                    setUpdateTeamColour(true);
                  }}
                >
                  Change team colour
                </MenuItem>
                {user.id === team.creator.id && (
                  <MenuItem
                    onClick={async () => {
                      setAnchorEl(null);
                      const res = await firebase
                        .firestore()
                        .collection('inviteCodes')
                        .add({
                          teamId: team.id
                        });

                      setInviteMember({ show: true, code: res.id });
                    }}
                  >
                    Invite new member
                  </MenuItem>
                )}
                {user.id === team.creator.id && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setSnackbarMessage('Deleting team...');

                      firebase
                        .firestore()
                        .collection('teams')
                        .doc(team.id)
                        .delete();
                    }}
                  >
                    Delete team
                  </MenuItem>
                )}
                {user.id !== team.creator.id && (
                  <MenuItem
                    onClick={async () => {
                      setAnchorEl(null);
                      setSnackbarMessage('Leaving team');

                      // first remove this user from every event they're attending for this team
                      for (const eventId of Object.keys(user.attending)) {
                        if (user.attending[eventId].teamId === team.id) {
                          await firebase
                            .firestore()
                            .collection('teams')
                            .doc(team.id)
                            .collection('events')
                            .doc(eventId)
                            .update({
                              [`attending.${user.id}`]:
                                firebase.firestore.FieldValue.delete()
                            });
                          await firebase
                            .firestore()
                            .collection('users')
                            .doc(user.id)
                            .update({
                              [`attending.${eventId}`]:
                                firebase.firestore.FieldValue.delete()
                            });
                        }
                      }

                      // then remove them from the team
                      await firebase
                        .firestore()
                        .collection('teams')
                        .doc(team.id)
                        .update({
                          [`members.${user.id}`]:
                            firebase.firestore.FieldValue.delete()
                        });

                      await firebase
                        .firestore()
                        .collection('users')
                        .doc(user.id)
                        .update({
                          [`teams.${team.id}`]:
                            firebase.firestore.FieldValue.delete()
                        });
                    }}
                  >
                    Leave team
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={team.name}
        />
        <CardContent>
          <Typography variant="body2" gutterBottom>
            Team created by {team.creator.name}
          </Typography>
          <Typography variant="body2">
            Number of members: {Object.keys(team.members).length}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Button size="small" onClick={() => setShowMembers(!showMembers)}>
            {showMembers ? 'Hide' : 'Show'} members
          </Button>
        </CardActions>
        <Collapse in={showMembers} timeout="auto" unmountOnExit>
          <CardContent>
            {team.members && (
              <List>
                {Object.keys(team.members).map(memberId => (
                  <Fragment key={memberId}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        {memberImageUrls[memberId] ? (
                          <Avatar
                            alt={team.members[memberId].name}
                            src={memberImageUrls[memberId]}
                          />
                        ) : (
                          <CircularProgress />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={team.members[memberId].name}
                        secondary={
                          <MemberLocalTimeInfo
                            tz={team.members[memberId].timezone}
                          />
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          disabled={!memberImageUrls[memberId]}
                          onClick={() =>
                            setMemberDetails({
                              show: true,
                              name: team.members[memberId].name,
                              bio: team.members[memberId].bio,
                              timezone: team.members[memberId].timezone,
                              imageUrl: memberImageUrls[memberId]
                            })
                          }
                        >
                          <Info />
                        </IconButton>
                        {user.id === team.creator.id && (
                          <IconButton
                            edge="end"
                            disabled={!memberImageUrls[memberId]}
                            onClick={async () => {
                              setSnackbarMessage('Removing member');

                              const outboundMember = (
                                await firebase
                                  .firestore()
                                  .collection('users')
                                  .doc(memberId)
                                  .get()
                              ).data();
                              // first remove this user from every event they're attending for this team
                              for (const eventId of Object.keys(
                                outboundMember.attending
                              )) {
                                if (
                                  outboundMember.attending[eventId].teamId ===
                                  team.id
                                ) {
                                  await firebase
                                    .firestore()
                                    .collection('teams')
                                    .doc(team.id)
                                    .collection('events')
                                    .doc(eventId)
                                    .update({
                                      [`attending.${memberId}`]:
                                        firebase.firestore.FieldValue.delete()
                                    });
                                  await firebase
                                    .firestore()
                                    .collection('users')
                                    .doc(memberId)
                                    .update({
                                      [`attending.${eventId}`]:
                                        firebase.firestore.FieldValue.delete()
                                    });
                                }
                              }

                              // then remove them from the team
                              await firebase
                                .firestore()
                                .collection('teams')
                                .doc(team.id)
                                .update({
                                  [`members.${memberId}`]:
                                    firebase.firestore.FieldValue.delete()
                                });

                              await firebase
                                .firestore()
                                .collection('users')
                                .doc(memberId)
                                .update({
                                  [`teams.${team.id}`]:
                                    firebase.firestore.FieldValue.delete()
                                });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </Fragment>
                ))}
              </List>
            )}
          </CardContent>
        </Collapse>

        <Dialog
          open={updateTeamColour}
          onClose={() => setUpdateTeamColour(false)}
        >
          <DialogTitle>Team Colour</DialogTitle>
          <DialogContent>
            <DialogContentText>
              What colour would you like to represent your {team.name} team?
            </DialogContentText>

            <ColorBox value={colour} onChange={evt => setColour(evt)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUpdateTeamColour(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setUpdateTeamColour(false);
                firebase
                  .firestore()
                  .collection('users')
                  .doc(user.id)
                  .update({
                    [`teams.${team.id}.colour`]: colour.css.backgroundColor
                  });
              }}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={inviteMember.show}
          onClose={() => setInviteMember({ show: false })}
        >
          <DialogTitle>Invite Team Member</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To invite a new member to your team, simply copy the code below,
              and share it with them.
            </DialogContentText>
            <TextField disabled value={inviteMember.code} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(inviteMember.code);
                setSnackbarMessage('Invite code copied.');
              }}
              color="primary"
            >
              Copy code
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={memberDetails.show}
          onClose={() => setMemberDetails({ show: false })}
        >
          <DialogTitle>{memberDetails.name}</DialogTitle>
          <DialogContent>
            <Avatar
              alt={user.name}
              style={{
                width: 290,
                height: 290
              }}
              src={memberDetails.imageUrl}
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText>{memberDetails.bio}</DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText variant="caption">
              <MemberLocalTimeInfo tz={memberDetails.timezone} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => setMemberDetails({ show: false })}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3300}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
    </>
  );
};

export default Team;
