import firebase from './firebase';

const getAcronym = teamName =>
  teamName
    ? teamName
        .split(' ')
        .map(word => word.charAt(0))
        .join('')
        .substring(0, 2)
    : '';

const randomColour = () =>
  '#000000'.replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });

const getImageDimensions = async () => {
  const { imageDimensions } = (
    await firebase.firestore().collection('settings').doc('app').get()
  ).data();

  return imageDimensions;
};

export { getAcronym, randomColour, getImageDimensions };
