import React from 'react';
import { Container, Paper, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3)
  }
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you need any help, or have any questions or feedback, please email
          us at{' '}
          <Link href="mailto:multiverse@createdeepconnections.com">
            multiverse@createdeepconnections.com
          </Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default Contact;
