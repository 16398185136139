import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const LocalTimeInfo = () => {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return (
    <>
      <Typography variant="subtitle1" align="center">
        {time.format('h:mm:ss a')}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {time.format('dddd, D MMMM YYYY')}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {dayjs.tz.guess()}
      </Typography>
    </>
  );
};

export default LocalTimeInfo;
