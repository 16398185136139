import React, { useState } from 'react';
import {
  Card,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
  CardHeader,
  CardContent,
  CardActions,
  Snackbar
} from '@material-ui/core';
import Confetti from 'react-confetti';
import firebase from '../modules/firebase';
import Team from './Team';

const Teams = ({ user }) => {
  const { teams, bio, id, name, timezone } = user;
  const [teamName, setTeamName] = useState();
  const [teamCode, setTeamCode] = useState();
  const [newTeam, setNewTeam] = useState(false);
  const [joinTeam, setJoinTeam] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  let teamsContent;

  if (!teams || Object.keys(teams).length === 0) {
    teamsContent = (
      <Typography variant="body1">
        Join or create a team to get started!
      </Typography>
    );
  } else {
    teamsContent = Object.keys(teams).map(teamId => (
      <Team
        team={{ ...teams[teamId], id: teamId }}
        user={{ ...user }}
        key={teamId}
      />
    ));
  }

  return (
    <>
      <Card>
        <CardHeader title="Teams" />
        <CardContent>{teamsContent}</CardContent>
        <CardActions>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setNewTeam(true)}
          >
            Create team
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setJoinTeam(true)}
          >
            Join team
          </Button>
        </CardActions>
      </Card>
      <Dialog open={newTeam} onClose={() => setNewTeam(false)}>
        <DialogTitle>Create a new team!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Simply choose the name of your team to get started.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Team name"
            fullWidth
            onChange={evt => setTeamName(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewTeam(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setNewTeam(false);
              setSnackbarMessage('Creating your new team...');
              await firebase
                .firestore()
                .collection('teams')
                .add({
                  name: teamName,
                  timestamps: { created: firebase.firestore.Timestamp.now() },
                  creator: {
                    id,
                    name
                  },
                  members: {
                    [id]: {
                      name,
                      timezone,
                      bio
                    }
                  }
                });
            }}
            color="primary"
          >
            Create team
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={joinTeam} onClose={() => setJoinTeam(false)}>
        <DialogTitle>Join a team 🙂</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the code provided to you by the team creator.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Code"
            fullWidth
            onChange={evt => setTeamCode(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJoinTeam(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setJoinTeam(false);
              setSnackbarMessage('Joining team...');

              const inviteSnapshot = await firebase
                .firestore()
                .collection('inviteCodes')
                .doc(teamCode)
                .get();
              if (inviteSnapshot.exists) {
                await firebase
                  .firestore()
                  .collection('teams')
                  .doc(inviteSnapshot.data().teamId)
                  .update({
                    [`members.${id}`]: {
                      name,
                      timezone,
                      bio
                    }
                  });
                setShowConfetti(true);
                firebase
                  .firestore()
                  .collection('inviteCodes')
                  .doc(teamCode)
                  .delete();
              } else {
                setSnackbarMessage('Sorry, your code is not valid.');
              }
            }}
            color="primary"
          >
            Join team
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3300}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={555}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </>
  );
};

export default Teams;
