import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  Paper,
  Typography,
  Box,
  Grid,
  Avatar,
  LinearProgress
} from '@material-ui/core';
import firebase from '../modules/firebase';
import { getImageDimensions } from '../modules/utils';
import Events from './Events';
import LocalTimeInfo from './LocalTimeInfo';
import Teams from './Teams';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Profile() {
  const [user, setUser] = useState();
  const [profileImageUrl, setProfileImageUrl] = useState();

  // for profile image
  useEffect(() => {
    const setProfileImage = async () => {
      const { max } = await getImageDimensions();

      try {
        const imageUrl = await firebase
          .storage()
          .ref()
          .child(`users/${user.id}/profile_${max}x${max}.jpeg`)
          .getDownloadURL();

        setProfileImageUrl(imageUrl);
      } catch (error) {
        setProfileImageUrl(`https://picsum.photos/${max}`);
      }
    };
    if (user) {
      setProfileImage();
    }
  }, [user]);

  // fetch the user
  useEffect(() => {
    const unsubscribeUser = firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(userSnapshot => {
        setUser({
          ...userSnapshot.data(),
          id: firebase.auth().currentUser.uid,
          email: firebase.auth().currentUser.email
        });
      });

    return () => unsubscribeUser();
  }, []);

  // for timezone and date
  useEffect(() => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .update({
        timezone: dayjs.tz.guess()
      });
  }, []);

  if (!user || !profileImageUrl) {
    return <LinearProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={3} style={{ padding: 11 }}>
        <Paper style={{ padding: 18 }}>
          <Box display="flex" justifyContent="center" marginBottom={1}>
            <Avatar
              alt={user.name}
              style={{
                width: 290,
                height: 290
              }}
              src={profileImageUrl}
            />
          </Box>
          <Typography variant="subtitle1" align="center">
            {user.name}
          </Typography>
          <Typography variant="subtitle2" align="center">
            {user.email}
          </Typography>
          <hr style={{ marginBottom: 11 }} />
          <LocalTimeInfo />
          <hr style={{ marginBottom: 11 }} />
          <Typography variant="subtitle1" align="center">
            "{user.bio}"
          </Typography>
        </Paper>
      </Grid>
      {user.teams && Object.keys(user.teams).length > 0 && (
        <Grid item xs={12} md={5} lg={5} style={{ padding: 11 }}>
          <Events user={user} />
        </Grid>
      )}
      <Grid item xs={12} md={3} lg={4} style={{ padding: 11 }}>
        <Teams user={user} />
      </Grid>
    </Grid>
  );
}
