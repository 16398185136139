import React, { Component } from 'react';
import { Container, Typography, Paper, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import minions from '../images/minions.jpg';
import errorSound from '../audio/minion-what.mp3';

const styles = theme => ({
  background: {
    backgroundImage: 'url(' + minions + ')',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    paddingTop: theme.spacing(38)
  },
  paper: {
    backgroundColor: 'rgba(0,0,0,0.65)',
    padding: theme.spacing(3),
    maxWidth: theme.spacing(51),
    color: 'white'
  }
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  render() {
    if (this.state.hasError) {
      new Audio(errorSound).play();
      const { classes } = this.props;
      return (
        <Container className={classes.background} maxWidth={false}>
          <Paper className={classes.paper}>
            <Typography variant="h3" gutterBottom component="h1">
              Uh oh.
            </Typography>
            <Typography variant="h5" component="p" gutterBottom>
              {this.state.error}
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you need help, please email us at{' '}
              <Link href="mailto:support@andrewgolightly.com">
                support@andrewgolightly.com
              </Link>
            </Typography>
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
