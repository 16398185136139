import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

const MemberLocalTimeInfo = props => {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return (
    <>
      {time.tz(props.tz).format('h:mma, D MMM YYYY')} ({props.tz})
    </>
  );
};

export default MemberLocalTimeInfo;
