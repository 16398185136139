import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Menu,
  MenuItem,
  Snackbar,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  useTheme
} from '@material-ui/core';
import firebase from '../modules/firebase';
import { MoreVert } from '@material-ui/icons';
import { getAcronym } from '../modules/utils';

const EventCard = ({ user, event, action, menu }) => {
  const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const googleCalDateFormat = 'YYYYMMDDTHHmmss';
  const calendarUrl = `http://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    event.title
  )} (${user.teams[event.teamId].name})&dates=${dayjs(
    event.timeOfEvent.toMillis()
  ).format(googleCalDateFormat)}/${dayjs(event.timeOfEvent.toMillis())
    .add(1, 'hour')
    .format(googleCalDateFormat)}&details=${encodeURIComponent(event.notes)}`;

  return (
    <Card variant="outlined" style={{ marginBottom: 11 }}>
      <CardHeader
        avatar={
          <Avatar
            style={{
              color: theme.palette.getContrastText(
                user.teams[event.teamId].colour
              ),
              backgroundColor: user.teams[event.teamId].colour
            }}
          >
            {getAcronym(user.teams[event.teamId].name)}
          </Avatar>
        }
        title={event.title}
        subheader={dayjs(event.timeOfEvent.toMillis()).format(
          'dddd, D MMMM YYYY @ h:mma'
        )}
        action={
          menu ? (
            <IconButton
              aria-label="settings"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <Typography variant="h6" color="textSecondary">
          Host
        </Typography>
        <Typography variant="body1">{event.host.name}</Typography>
        <Typography variant="h6" color="textSecondary">
          Notes
        </Typography>
        <Typography
          variant="body1"
          style={{ whiteSpace: 'pre-wrap' }}
          gutterBottom
        >
          {event.notes}
        </Typography>
        {Object.keys(event.attending).length > 0 && (
          <Typography variant="body2" color="textSecondary">
            Attendees:{' '}
            {Object.keys(event.attending)
              .map(attendeeId => event.attending[attendeeId].name)
              .join(', ')}
          </Typography>
        )}
      </CardContent>
      <CardActions>{action}</CardActions>
      {menu && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              window.open(calendarUrl);
            }}
          >
            Add to calendar
          </MenuItem>
          {event.host.id === user.id && (
            <MenuItem
              onClick={() => history.push(`/event/${event.teamId}/${event.id}`)}
            >
              Edit event
            </MenuItem>
          )}
          {event.host.id === user.id ? (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setSnackbarMessage('Deleting event');

                firebase
                  .firestore()
                  .collection('teams')
                  .doc(event.teamId)
                  .collection('events')
                  .doc(event.id)
                  .delete();
              }}
            >
              Delete event
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setSnackbarMessage('Leaving event');
                firebase
                  .firestore()
                  .collection('teams')
                  .doc(event.teamId)
                  .collection('events')
                  .doc(event.id)
                  .update({
                    [`attending.${user.id}`]:
                      firebase.firestore.FieldValue.delete()
                  });
                firebase
                  .firestore()
                  .collection('users')
                  .doc(user.id)
                  .update({
                    [`attending.${event.id}`]:
                      firebase.firestore.FieldValue.delete()
                  });
              }}
            >
              Leave event
            </MenuItem>
          )}
        </Menu>
      )}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3300}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
    </Card>
  );
};

export default EventCard;
