/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Paper,
  Typography,
  Button,
  useTheme
} from '@material-ui/core';
import Confetti from 'react-confetti';
import Event from './EventCard';
import firebase from '../modules/firebase';

export default ({ user }) => {
  const history = useHistory();
  const theme = useTheme();
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const fetchAllUpcomingTeamEvents = async () => {
      const allUpcomingEvents = [];
      const eventsAttending = user.attending ? Object.keys(user.attending) : [];
      if (user.teams) {
        for (const teamId of Object.keys(user.teams)) {
          const eventsSnapshot = await firebase
            .firestore()
            .collection('teams')
            .doc(teamId)
            .collection('events')
            .get();
          for (const eventSnapshot of eventsSnapshot.docs) {
            if (!eventsAttending.includes(eventSnapshot.id)) {
              allUpcomingEvents.push(
                <Event
                  user={user}
                  key={eventSnapshot.id}
                  event={{
                    ...eventSnapshot.data(),
                    id: eventSnapshot.id,
                    teamId
                  }}
                  menu={false}
                  action={
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        setShowConfetti(true);
                        firebase
                          .firestore()
                          .collection('teams')
                          .doc(teamId)
                          .collection('events')
                          .doc(eventSnapshot.id)
                          .update({
                            [`attending.${user.id}`]: {
                              name: user.name
                            }
                          });
                      }}
                    >
                      Attend event
                    </Button>
                  }
                />
              );
            }
          }
        }
      }
      setUpcomingEvents(allUpcomingEvents);
    };
    fetchAllUpcomingTeamEvents();
  }, [user, theme.palette]);

  let eventContent;
  if (!upcomingEvents) {
    eventContent = <CircularProgress />;
  } else {
    let eventsAttending;
    if (user.attending) {
      eventsAttending = Object.keys(user.attending).map(eventId =>
        user.teams[user.attending[eventId].teamId] ? (
          <Event
            event={{ ...user.attending[eventId], id: eventId }}
            user={user}
            key={eventId}
            menu={true}
            action={
              <Button
                size="small"
                color="primary"
                href={user.attending[eventId].videoChatUrl}
                target="_blank"
              >
                Join call
              </Button>
            }
          />
        ) : null
      );
    }

    eventContent = (
      <>
        <Typography variant="h6" gutterBottom>
          Events you're attending
        </Typography>
        {eventsAttending && eventsAttending.length > 0 ? (
          eventsAttending
        ) : (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            You're not attending any upcoming events.
          </Typography>
        )}
        <hr />
        <Typography variant="h6" gutterBottom>
          Available events
        </Typography>
        {upcomingEvents.length > 0 ? (
          upcomingEvents
        ) : (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            There are currently no other upcoming events for your teams.
          </Typography>
        )}
        <hr />
        <Button
          variant="contained"
          size="small"
          style={{ marginTop: theme.spacing(3) }}
          color="primary"
          onClick={() => history.push('/event')}
        >
          Create event
        </Button>
      </>
    );
  }

  return (
    <>
      <Paper style={{ padding: theme.spacing(3) }}>
        <Typography variant="h5" gutterBottom>
          Events
        </Typography>
        <hr />
        {eventContent}
      </Paper>
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={555}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
    </>
  );
};
