import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ExpandMore } from '@material-ui/icons';
import {
  AppBar,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  Grow
} from '@material-ui/core';
import firebase from '../modules/firebase';

const Dashboard = props => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState();

  useEffect(() => {
    // watch for changes to user data
    const unsubscribeUser = firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(userSnapshot => {
        setUser(userSnapshot.data());
      });

    // watch for auth change / signing out
    const authChange = firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        history.push('/signin');
      } else {
        firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .update({
            'timestamps.lastLoggedIn': firebase.firestore.Timestamp.now()
          });
      }
    });

    return () => {
      authChange();
      unsubscribeUser();
    };
  }, [history]);

  return (
    <>
      <AppBar color="inherit">
        <Toolbar>
          <Grow in style={{ transformOrigin: '0 0 0' }} timeout={3000}>
            <Button
              style={{ marginRight: 'auto' }}
              size="large"
              onClick={() => history.replace('/')}
            >
              Multiverse
            </Button>
          </Grow>
          {user && (
            <Button
              endIcon={<ExpandMore />}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              {user.name}
            </Button>
          )}

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                history.push(
                  `/profile/edit/${firebase.auth().currentUser.uid}`
                );
                setAnchorEl(null);
              }}
            >
              Edit profile
            </MenuItem>
            <MenuItem onClick={() => firebase.auth().signOut()}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <main style={{ marginTop: 77 }}>{props.children}</main>
    </>
  );
};

export default Dashboard;
