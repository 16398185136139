import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import firebase from './modules/firebase';

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import Event from './components/Event';
import Contact from './components/Contact';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: blue
        }
      }),
    [prefersDarkMode]
  );

  const NavRoute = ({ exact, path, component: Component }) => (
    <Route
      exact={exact}
      path={path}
      render={props =>
        firebase.auth().currentUser ? (
          <Dashboard>
            <Component {...props} />
          </Dashboard>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <Router>
          <CssBaseline />
          <ErrorBoundary>
            <Switch>
              <NavRoute exact path="/" component={Profile} />
              <NavRoute
                exact
                path="/profile/edit/:userId"
                component={EditProfile}
              />
              <Route exact path="/signin" component={Login} />
              <NavRoute path="/event/:teamId?/:eventId?" component={Event} />
              <NavRoute path="/contact" component={Contact} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
